<template>
    <div class="vip-content">
        <div class="cont-header">
           <span>发票记录</span>
        </div>
         <div class="cont">
            <a-table 
                :columns="columns" 
                :data-source="dataTableList" 
                style="border: 1px solid #EBF0F2;table-layout: fixed;" 
                :pagination="pagination"
                :loading="loading"
                :rowKey="(scope) => scope.invoice_id"
            >  
                <template slot="create_at-info" slot-scope="text, scope">
                   <a-row>
                    <a-col :span=24>
                        <span>{{scope.order_no}}</span>
                    </a-col>
                   </a-row>
                </template>
                <template slot="order_type" slot-scope="text, scope">
                   <a-row>
                    <a-col :span=24>
                        <span v-if="scope.order_type==1">零售订单</span>
                        <span v-if="scope.order_type==2">套餐订单</span>
                        <span v-if="scope.order_type==3">会员订单</span>
                    </a-col>
                   </a-row>
                </template>
                <template slot="is_operated-info" slot-scope="text, scope">
                   <a-row>
                    <a-col :span=24 v-if="scope.is_operated==1">
                        <a-tooltip :title="scope.order_no"> <span style="color: #54BCAC;">已开票</span></a-tooltip>
                    </a-col>
                    <a-col :span=24 v-else>
                        <a-tooltip title="管理员会在 15 个工作日内向你邮寄发票，请注意查收"><span style="color: #FF7B13;">审核中</span></a-tooltip>
                    </a-col>
                   </a-row>
                </template>
                <template slot="operated-info" slot-scope="text, scope">
                   <a-row>
                    <a-col :span=24 style="cursor:pointer;">
                        <span @click="onLookReceipt(scope)">查看详情</span>
                    </a-col>
                   </a-row>
                </template>
            </a-table>
        </div>
        <dialog-receipt-result
        :visible.sync="showDialogReceiptResult"
        :data="handleDataReceiptResult"
        :invoice_id="handleDataReceiptResult.invoice_id"
        @success="onSuccess"
        ></dialog-receipt-result>
    </div>
</template>
<script>
    import {Table as ATable, Tooltip as ATooltip} from 'ant-design-vue';
    import {toOptionName} from '@/utils/function';
    import {ReceiptHeaderTypeOptions, ReceiptTypeOptions} from '@/config/options';
    import DialogReceiptResult from '@/pages/member/order/components/result/NewDialogReceiptResult.vue'
    export default {
        data() {
            return {
                loading: true,
                pagination: {
                    "show_pagin" : true,
                    "show-quick-jumper": true,
                    "default-current":1,
                    total:0,
                    pageSize: 20,
                    current: 0,
                    onChange: this.onChangePage,
                    hideOnSinglePage: true,
                    style:{
                        "float": "none",
                        "text-align": "center",
                    },
                },
                columns:[
                    {
                        title: '订单编号',
                        dataIndex: 'order_no',
                        scopedSlots: { customRender: 'create_at-info'},
                    },
                    {
                        title: '订单类型',
                        dataIndex: 'order_type',
                        scopedSlots: { customRender: 'order_type'},
                    },
                    {
                        title: '申请时间',
                        dataIndex: 'create_at',
                    },
                     {
                        title: '发票状态',
                        align:'center',
                        dataIndex: 'status',
                        scopedSlots: { customRender: 'is_operated-info'},
                    },
                    {
                        title: '操作',
                        align:'center',
                        dataIndex: 'is_operated',
                        scopedSlots: { customRender: 'operated-info'},
                    },
                   
                ],
                dataTableList:[],
                handleDataReceiptResult: {},
                showDialogReceiptResult: false,
            }
        },
        components: {
            ATable,
            ATooltip,
            DialogReceiptResult
        },
        created: function () {
            this.getTableData();
        },
        filters: {
            toReceiptHeaderType(value) {
                return toOptionName(ReceiptHeaderTypeOptions, value);
            },

            toReceiptType(value) {
                return toOptionName(ReceiptTypeOptions, value);
            },
        },
        methods: {
            getTableData(params){
                this.$axios.Order_receiptList(params).then(res => {
                    if (res.data.code == 0) {
                        this.dataTableList = res.data.data.list;
                        this.pagination.total = res.data.data.total;
                        this.loading = false;
                    }
                })
            },
            onChangePage(nowpage) {
                this.loading = true;
                let params = {
                    page:nowpage,
                }
                this.getTableData(params);
                this.pagination.current = nowpage;
                this.pagination = Object.assign({}, this.pagination);
            },
            onSuccess(scope) {
                this.showDialogReceiptResult = false;
            },
            onLookReceipt(scope) {
                this.handleDataReceiptResult = Object.assign({}, scope);
                this.showDialogReceiptResult = true;
            },
        },
    }
</script>
<style lang="scss" scoped>
    .cont-header{
        height: 66px;
        line-height: 66px;
        border-bottom:1px solid #F0F4F5;
        span{
            display: inline-block;
            font-size: 16px;
            font-weight: 400;
            color: #333333;
            line-height: 22px;
            height: 22px;
        }
        span:nth-child(1){
            width: 20%;
            padding-left: 32px;
        }
        span:nth-child(2){
            cursor: pointer;
            width: 80%;
            color: #FF7B13;
            text-align: right;
            padding-right:32px;
        }
    }
    .cont{
        width: 90%;
        margin: 0 auto;
        padding-top:24px;
    }
    .auth-info{
        p{
            font-size: 14px;
            font-weight: 400;
            color: #666666;
            line-height: 24px;
        }
        p:nth-child(4){
            color: #333333;
        }
    }
</style>